import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: 'certificate/:version/:certificateId', loadComponent: () => import('./components/certificate-layout/certificate-layout.component').then(mod => mod.CertificateLayoutComponent)},
  { path: '**', pathMatch: 'full', loadComponent: () => import('./components/not-found/not-found.component').then(mod => mod.NotFoundComponent)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
