import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { environment } from "../environments/environment";
import { RECAPTCHA_LOADER_OPTIONS } from "ng-recaptcha";


@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], 
    imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatFormFieldModule,
        MatIconModule,
        MatTooltipModule,
        FormsModule,
        MatProgressSpinnerModule
    ], 
    providers: [
        {
            provide: RECAPTCHA_LOADER_OPTIONS,
            useValue: {
                onBeforeLoad(_url: any) {
                    return {
                        url: new URL("https://www.recaptcha.net/recaptcha/api.js"), // use recaptcha.net script source for some of our users
                    };
                },
            },
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
